import {
  createEffect,
  createMemo,
  createRenderEffect,
  on,
  Show,
} from "solid-js";
import { createStore } from "solid-js/store";
import ProgramsList from "~/components/shared/ProgramsList";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { Landing, ProgramRefWithLocation } from "~/types/drupal_jsonapi";

import "./LandingPrograms.css";
import { useSearchParams } from "@solidjs/router";
import { transliterate } from "~/utils/format";
import { InputTypeText } from "~/components/Forms/Fields/Base/InputTypeText";
import {
  Pagination,
  paginationSlices,
  type ProgramPaginationStore,
} from "~/components/shared/Pagination";
import { getCanonicalPathFromMetaData } from "~/utils/tools";
import { urlRs } from "~/utils/url";

const debug = false;

type LandingProgramPaginationStore = ProgramPaginationStore & {
  locationFilter: string | undefined;
};

const [store, setStore] = createStore<LandingProgramPaginationStore>({
  sort: "cp",
  slices: [],
  currentPage: 0,
  displayedPrograms: [],
  locationFilter: undefined,
});

export default function LandingPrograms(props: { landing: Landing }) {
  const settings = useDrupalSettingsContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const path = () => {
    const dest = getCanonicalPathFromMetaData(props.landing.metatag);
    const suffix = store.sort === "ville" ? "?sort=ville" : "";
    return urlRs("landings", dest + suffix, settings);
  };

  const sortFn = (a: ProgramRefWithLocation, b: ProgramRefWithLocation) => {
    if (store.sort === "cp") {
      return a.postal_code.localeCompare(b.postal_code);
    } else {
      return a.city.localeCompare(b.city);
    }
  };

  const filterFn = (p: ProgramRefWithLocation) => {
    if (store.locationFilter) {
      return (
        transliterate(p.city)
          .toLowerCase()
          .includes(store.locationFilter!.toLowerCase()) ||
        p.postal_code.includes(store.locationFilter!)
      );
    } else {
      return true;
    }
  };

  createRenderEffect(() => {
    setStore("currentPage", parseInt((searchParams.page as string) || "0"));
  });

  createRenderEffect(() => {
    setStore("sort", (searchParams.sort as "cp" | "ville") || "cp");
  });

  const showPromoCard = () => {
    return settings.promo_card.disp_landings && store.currentPage === 0;
  };

  const filteredPrograms = createMemo(
    on(
      () => [store.locationFilter, store.sort],
      () => props.landing.programs?.filter(filterFn) ?? [],
    ),
  );

  createRenderEffect(() => {
    const slices = paginationSlices(filteredPrograms().toSorted(sortFn), {
      insertPromoCard: settings.promo_card.disp_landings,
    });
    setStore("slices", slices);
  });

  createEffect(() => {
    on(
      () => [store.locationFilter, store.sort, store.slices],
      () => {
        setSearchParams({ page: 0, sort: store.sort, r: searchParams.r });
      },
    );
  });

  createEffect(() => {
    if (store.slices[store.currentPage]) {
      setStore(
        "displayedPrograms",
        store.slices[store.currentPage].filter(Boolean),
      );
    }
  });

  return (
    <>
      <section
        class="programs-landing"
        id="programs-landing"
        data-test="programs-landing"
      >
        <div class="content-part">
          <Show when={props.landing.field_programs_title}>
            <h2 class="title">{props.landing.field_programs_title}</h2>
          </Show>

          <Show when={debug}>
            <pre>
              Params: {JSON.stringify(searchParams, null, 2)}
              Filtered programs: {filteredPrograms().length}
              <br />
              Current page: {store.currentPage}
              <br />
              Sort: {store.sort}
              <br />
              Location filter: {store.locationFilter}
              <br />
              Slices:{" "}
              {JSON.stringify(
                store.slices.map((s) => s.map((p) => p?.id_pp_program)),
                null,
                2,
              )}
              <br />
              <br />
              <hr />
              <br />
              Displayed:
              {JSON.stringify(
                store.displayedPrograms?.map((p) => p?.id_pp_program),
                null,
                2,
              )}
            </pre>
          </Show>

          <Show
            when={props.landing.programs && props.landing.programs.length > 0}
          >
            <div class="filters" data-test="filters">
              <div class="search-input">
                <InputTypeText
                  name="location-filter"
                  label="Où ?"
                  onInput={(event) => {
                    setSearchParams({ page: undefined, r: "s" });
                    setStore(
                      "locationFilter",
                      transliterate(event.currentTarget.value).toLowerCase(),
                    );
                  }}
                  value=""
                />
              </div>
              <div class="sort" data-test="sort">
                Trier par :
                <button
                  type="button"
                  classList={{ active: store.sort !== "ville" }}
                  onClick={() => {
                    setSearchParams({
                      page: undefined,
                      r: "s",
                      sort: undefined,
                    });
                    setStore("sort", "cp");
                  }}
                  data-test="filter-postal-code"
                >
                  Code postal
                </button>
                <button
                  type="button"
                  classList={{ active: store.sort === "ville" }}
                  onClick={() => {
                    setSearchParams({
                      page: undefined,
                      r: "s",
                      sort: "ville",
                    });
                    setStore("sort", "ville");
                  }}
                  data-test="filter-city"
                >
                  Ville
                </button>
              </div>
            </div>

            <div class="flex-column">
              <Show when={!store.displayedPrograms?.length}>
                <section class="no-result">
                  <p>
                    Nous n'avons pas trouvé de logement correspondant à votre
                    recherche. Nous vous conseillons d'élargir la zone
                    recherchée.
                  </p>
                </section>
              </Show>
              <section
                id="programs"
                class="programs-list"
                data-ga-zone="list"
                data-test="programs"
              >
                <ProgramsList
                  programs={store.displayedPrograms}
                  showPromoCard={showPromoCard()}
                />
              </section>

              <Show when={store.slices.length > 1}>
                <Pagination
                  currentPage={store.currentPage}
                  totalPages={store.slices.length}
                  url={path()!}
                  type="landings"
                  scrollTo="#programs"
                />
              </Show>
            </div>
          </Show>
        </div>
      </section>
    </>
  );
}
